import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Loading = () => (
  <>
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id="your-unique-id" x1="1" y1="0" x2="0" y2="0">
          <stop offset="5%" stopColor="#ff0000cc" />
          <stop offset="95%" stopColor="#ff8800ce" />
        </linearGradient>
      </defs>
    </svg>
    <CountdownCircleTimer colors="url(#your-unique-id)" isPlaying duration={6}>
      {({ remainingTime }) => remainingTime}
    </CountdownCircleTimer>
  </>
);

export default Loading;
