import React from "react";
import Form from "../Form/Form";
import classes from "./Login.module.css";

const Login = () => {
  return (
    <div className={classes.signinContainer}>
      <div className={classes.header}>
        <h1>Sign in</h1>
        <a className={classes.registrationButton} href="/register">
          Registration
        </a>
      </div>
      <Form />
    </div>
  );
};

export default Login;
