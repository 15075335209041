import React from "react";
import classes from "./GameAnimation.module.css";
import Balloon from "../../Balloon/Balloon";
import CircularProgress from "../../UI/Loading";

const GameAnimation = ({ isPopped, multiplier, resultShowed }) => {
  return (
    <div className={classes.gameAnimations}>
      {isPopped ? (
        resultShowed ? (
          <div className={classes.results}>
            <p>{multiplier.toFixed(2)}x</p>
            <img src="./images/multiplier_result.png" alt="multiplier result" />
          </div>
        ) : (
          <CircularProgress />
        )
      ) : (
        <Balloon isPopped={isPopped} multiplier={multiplier.toFixed(2)} />
      )}
    </div>
  );
};

export default GameAnimation;
