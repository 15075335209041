import React from "react";
import Form from "../Form/Form";
import classes from "./Login.module.css";

const Register = () => {
  return (
    <div className={classes.signinContainer}>
      <div className={classes.header}>
        <h1>Sign up</h1>
        <a className={classes.registrationButton} href="/login">
          Sign in
        </a>
      </div>
      <Form />
    </div>
  );
};

export default Register;
