import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import classes from "./Form.module.css";
import { useLocation } from "react-router-dom";
import Button from "../UI/Button/Button";

const Form = () => {
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState("");
  const [password2, setPassword2] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const onLogin = () => {
    // Check if the user has entered both fields correctly

    if (nickname === "" || password === "") {
      setFormError("Please fill all the fields.");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/auth/login/`, {
        nickname: nickname,
        password: password,
      })
      .then((response) => {
        localStorage.setItem("accessToken", response.data.access);
        localStorage.setItem("refreshToken", response.data.refresh);
        // Handle response here. If login is successful, navigate to another page or set user context
        console.log(
          "Login successful",
          response,
          localStorage.getItem("accessToken"),
          localStorage.getItem("refreshToken")
        );
        navigate("/aviator_game");
      })
      .catch((error) => {
        setFormError("Wrong nickname or password!");
      });
  };

  const onRegister = () => {
    if (password !== password2) {
      setFormError("Passwords do not match.");
      return;
    }

    if ([nickname, password, name, surname, email, password2].includes("")) {
      setFormError("Please fill all the fields.");
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setFormError("Email is invalid");
      return;
    }

    // Axios call to your API endpoint
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/auth/register/`, {
        nickname,
        name,
        surname,
        email,
        password,
        password2,
      })
      .then((response) => {
        navigate("/login"); // Redirect to login after successful registration
      })
      .catch((error) => {
        console.error("Registration failed", error);
        // Handle other errors here as required
      });
  };

  return (
    <div className={classes.formContainer}>
      <input
        type="text"
        className={classes.inputField}
        value={nickname}
        placeholder="Enter your nickname here"
        onChange={(ev) => setNickname(ev.target.value)}
      />
      {location.pathname === "/register" && (
        <>
          <input
            className={classes.inputField}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
          <input
            className={classes.inputField}
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            placeholder="Surname"
          />
          <input
            className={classes.inputField}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </>
      )}
      <input
        className={classes.inputField}
        type="password"
        value={password}
        placeholder="Enter your password here"
        onChange={(ev) => setPassword(ev.target.value)}
      />
      {location.pathname === "/register" && (
        <input
          className={classes.inputField}
          type="password"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          placeholder="Confirm Password"
        />
      )}
      <div className={classes.buttonDiv}>
        <label className={classes.formErrorLabel}>{formError}</label>

        <Button
          text="Submit"
          clickFunction={location.pathname === "/login" ? onLogin : onRegister}
        />
      </div>
    </div>
  );
};

export default Form;
