import React from "react";
import classes from "./Login.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../UI/Button/Button";

const LogOut = () => {
  const navigate = useNavigate();

  const logOut = () => {
    const refreshToken = localStorage.getItem("refreshToken");

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/logout/`,

        { refresh: refreshToken },
        config
      )
      .then((response) => {
        // Remove tokens from local storage
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        navigate("/login"); // Redirect to login after successful logout
        console.log(response);
      })
      .catch((error) => {
        console.error("Logout failed", error);
        // Handle other errors here as required
      });
  };

  return <Button text="Log out" clickFunction={logOut} />;
};

export default LogOut;
