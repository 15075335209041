import React from "react";
import classes from "./Balloon.module.css";

const Balloon = ({ isPopped, multiplier }) => {
  const balloonContainerStyle = {
    animation: isPopped ? "none" : "float 3s ease-in-out infinite",
  };

  const balloonStyle = {
    transform: isPopped ? "scale(0)" : "scale(1)",
  };

  const keyframesStyle = `
    @keyframes float {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-20px);
      }
    }
  `;

  return (
    <>
      <style>{keyframesStyle}</style>
      <div style={balloonContainerStyle} className={classes.balloonContainer}>
        <img
          src="/images/balloon.png"
          alt="Balloon"
          style={balloonStyle}
          className={classes.balloon}
        />
        {!isPopped && <span className={classes.multiplier}>{multiplier}x</span>}
      </div>
    </>
  );
};

export default Balloon;
