import React from "react";
import classes from "./Button.module.css";

const Button = ({ text, clickFunction, disabled }) => {
  return (
    <button
      className={classes.submitButton}
      onClick={clickFunction}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
