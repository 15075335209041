import React, { useState, useEffect } from "react";
import axios from "axios";
import Balloon from "../Balloon/Balloon";
import classes from "./Game.module.css";
import CircularProgress from "./../UI/Loading";
import LogOut from "./../Authorization/LogOut";
import GameAnimation from "./GameAnimation/GameAnimation";

const GameComponent = () => {
  const [bet, setBet] = useState("");
  const [status, setStatus] = useState("Please bet for the next round");
  const [socket, setSocket] = useState(null);
  const [multiplier, setMultiplier] = useState(1); // Default to 1 or any initial value
  const [winAmount, setWinAmount] = useState({ amount: 0, mult: 0 }); // Initialize to 0
  const [currentRoundId, setCurrentRoundId] = useState(null);
  const [betRoundId, setBetRoundId] = useState(null);
  const [balance, setBalance] = useState(0); // State to track player's balance
  const [activeButton, setActiveButton] = useState("bet");
  const [nextRoundId, setNextRoundId] = useState();
  const [isPopped, setIsPopped] = useState(false);
  const [resultShowed, setResultShowed] = useState(false);

  // Axios instance for HTTP requests with JWT token in the header
  const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    // baseURL: "http://localhost:9000/",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });

  // Function to fetch current balance
  const fetchBalance = async () => {
    try {
      const response = await api.get("/balance/");
      setBalance(response.data.balance);
    } catch (error) {
      console.error(
        "Error fetching balance:",
        error.response?.data || error.message
      );
    }
  };

  // Call fetchBalance when component mounts to initialize the balance
  useEffect(() => {
    fetchBalance();
  }, []);

  useEffect(() => {
    // Initialize WebSocket connection with JWT token
    const accessToken = localStorage.getItem("accessToken");
    // const socketUrl = `ws://localhost:9000/ws/game/?token=${accessToken}`;
    const socketUrl = `${process.env.REACT_APP_WEBSOCKET}/ws/game/?token=${accessToken}`;
    const newSocket = new WebSocket(socketUrl);
    setSocket(newSocket);

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // Handle incoming WebSocket messages here
      console.log("Message from server:", data);

      setNextRoundId(data.next_round_id);
      setCurrentRoundId(data.round_id);

      data.multiplier && setMultiplier(data.multiplier);

      if (data.type === "round.end") {
        setIsPopped(true);
        setResultShowed(true);
        setStatus("Round ended");

        if (data.is_bet_current_round && winAmount.amount === 0) {
          setActiveButton("bet");
          setBet(0);
          return;
        }
      } else {
        setIsPopped(false);
        setStatus("Round started");
      }
    };
    newSocket.onerror = (event) => {
      console.error("WebSocket error:", event);
    };

    return () => {
      newSocket.close();
    };
  }, []);

  const handleBetChange = (event) => {
    setBet(event.target.value);
  };

  const placeBet = async (event) => {
    try {
      if (/^-?\d*$/.test(bet) && parseInt(bet, 10) < 1) {
        setStatus("Bet wasn't placed. Please enter only positive numbers");
        return;
      }
      const response = await api.post("/bet/", {
        bet_amount: parseInt(bet),
        round: nextRoundId,
      });
      console.log(response.data);
      setStatus(response.data.message);
      setActiveButton("cashOut");
    } catch (error) {
      console.error(
        "Error placing bet:",
        error.response?.data || error.message
      );
      setStatus(error.response?.data.error || "Error placing bet");
    }
    await fetchBalance(); // Update balance after place Bet
    setBetRoundId(nextRoundId);
  };

  const cashOut = async () => {
    try {
      const response = await api.post("/cashout/", { round: currentRoundId });
      setStatus(response.data.message || response.data.error);
      setWinAmount({ amount: response.data.win_amount, mult: multiplier }); // Update win amount from response
      setBet("0");
      await fetchBalance(); // Update balance after cashing out
      winAmount && setActiveButton("bet");
    } catch (error) {
      console.error(
        "Error cashing out:",
        error.response?.data || error.message
      );
      setStatus(error.response?.data.error || "Error cashing out");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setResultShowed(false);
      if (isPopped && resultShowed) {
        setWinAmount({ amount: 0, mult: 0 });
      }
    }, 9000);

    return () => clearTimeout(timer);
  }, [resultShowed]);

  return (
    <div className={classes.container}>
      <div>
        <LogOut />
      </div>
      <GameAnimation
        isPopped={isPopped}
        resultShowed={resultShowed}
        multiplier={multiplier}
      />

      <p>Status: {status}</p>
      <p>Winning Amount: {winAmount.amount.toFixed(2)}</p>
      {winAmount.amount > 0 && <p>Multiplayer: {winAmount.mult.toFixed(2)}</p>}
      <p>Current Balance: {balance.toFixed(2)}</p>
      <div className={classes.inputButtonDiv}>
        <input
          className={classes.inputField}
          type="number"
          value={bet}
          onChange={handleBetChange}
          placeholder="Enter your bet..."
          inputmode="numeric"
        />
        {activeButton === "bet" ? (
          <button onClick={placeBet}>Bet</button>
        ) : (
          <button onClick={cashOut} disabled={currentRoundId !== betRoundId}>
            Cash Out
          </button>
        )}
      </div>
    </div>
  );
};

export default GameComponent;
